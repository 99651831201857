/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import SellAPI from '../../api/SellAPI';
import OrderAPI from '../../api/OrderAPI';
import CotizarAPI from '../../api/CotizarAPI';
import { fetchTimbrado } from './TimbrarActions';
import { removeCustomer } from './customerActions';
import { clearCart, alertProducts } from './cartActions';
import { addItemsToTable, setTicket } from './tableActions';
import { removePricebook } from './priceBookActions';
import {
  selectOrder,
  resetEditOrder,
  setActivityToOrder,
  changeOrderStatus,
  fetchOrderView,
  selectOrderAndOpenTicket,
} from './orderActions';
import {
  selectCotizacion,
  fetchAllCotizaciones,
} from './cotizarActions';
import { openModal, closeModal } from './modalActions';
import { loading, loaded } from './loadActions';
import { changeTab } from './tabActions';
import GuideApi from '../../api/GuideApi';
import { setTicketData } from './ticketAction';
import TableAPI from '../../api/TableAPI';
import { clearShipping } from './shippingActions';
import { printerHelper } from '../../helpers';

export const CHANGE_SELL_CASH = 'CHANGE_SELL_CASH';
export const CHANGE_SELL_CARD = 'CHANGE_SELL_CARD';
export const CHANGE_SELL_ORDER_STATUS = 'CHANGE_SELL_ORDER_STATUS';
export const CLEAR_SELL = 'CLEAR_SELL';

export function changeSellCash(cash) {
  return {
    type: CHANGE_SELL_CASH,
    cash,
  };
}

export function changeSellCard(card) {
  return {
    type: CHANGE_SELL_CARD,
    card,
  };
}

export function changeSalesOrderStatus(sostatus) {
  return {
    type: CHANGE_SELL_ORDER_STATUS,
    sostatus,
  };
}


export function clearSell() {
  return {
    type: CLEAR_SELL,
  };
}

const displayGroupMessage = (message) => {
  if (message.success) {
    toast.success(` ✔ ${message.success}`);
  }

  else if (message.warning) {
    toast.warn(` ❕ ${message.warning}`);
  }

  else if (message.error) {
    toast.error(` ❌ ${message.error}`);
  }

  else {
    toast.success(` ❔ ${message.information}`);
  }
};

export function makeSell(_payments) {
  console.log('MAKE SELL');
  let toastId = null;
  return async (dispatch, getState) => {
    const sellApi = new SellAPI();
    const {
      cart,
      sell,
      discount,
      customer,
      authUser,
      timbrar: { makeBill },
      filters,
      shipping,
      order: { orderData: { cvid } },
    } = getState();
    dispatch(loading('sell'));
    dispatch(loading('checkout')); /* Hot FIX */
    const msg = ' 🚀 Realizando cobro...';
    toastId = toast(msg, { autoClose: false, type: toast.TYPE.INFO, progress: 0.20 });
    const {
      products, subTotal, totalIva, description,
    } = cart;
    const { selectedCustomer } = customer;
    try {
      const { sostatus } = sell;
      const { shippingList } = shipping;
      const { user: { almacenes, config: { pos_auto_alm, store, pos_sell_tab } } } = authUser;
      const payments = _payments.map(x => ({ ...x, referencia: '' }));
      const deliver = Boolean(pos_auto_alm === 'Siempre');
      const printConfig = printerHelper.createPrintOrder(authUser.user);
      const response = await sellApi.create(cart, discount, customer, sostatus, payments, deliver, filters, printConfig);
      const { success, message, result, error } = await response;

      if (success) {
        const { crmid, label } = result;
        if (message.success !== null
          && message.success !== undefined
          && message.success !== '') {
          await toast.update(toastId, {
            type: toast.TYPE.SUCCESS,
            progress: 0.60,
            autoClose: false,
            render: message.success,
          });
        }
        if (message.warning !== null
          && message.warning !== undefined
          && message.warning !== '') {
          await toast.warn(message.warning);
        }
        toast.warn(message.warning);
        const { user: { config: { pos_checkout_bill, pos_print_after_sell } } } = authUser;
        const billConfig = Boolean(pos_checkout_bill === 'Siempre');

        // Create guide
        if (shippingList.length) {
          // eslint-disable-next-line no-use-before-define
          await createGuideAndProducts({
            order: result, shippingList, store, customer, almacenes,
          });
          // clear shippings
          dispatch(clearShipping());
        }

        if (pos_print_after_sell && printConfig === null) {
          const prods = [...products];
          const rewardIndex = result.json_detail.findIndex(prod => prod.productid && prod.productid.includes('96866'));
          if (rewardIndex !== -1) {
            prods.push(result.json_detail[rewardIndex]);
          }
          await dispatch(setTicketData(selectedCustomer, result, prods, subTotal, totalIva, description || '', 'order'));
          // await dispatch(setTicketData(selectedCustomer, result, products, subTotal, totalIva, description || '', 'order'));
        }

        await dispatch(clearCart());

        /*  if (!pos_print_after_sell) {
          await dispatch(selectOrder(crmid));
        } */
        switch (pos_auto_alm) {
          case 'Preguntar':
            if (pos_sell_tab) {
              await dispatch(changeTab('posType', 'Historico'));
              await dispatch(selectOrder(crmid));
              await dispatch(openModal('deliver'));
              await dispatch(addItemsToTable('deliver'));
            }
            break;
          default:
            // dispatch(fetchAllOrders());
            // dispatch(fetchOrderView(cvid));
            dispatch(removeCustomer());
            // await dispatch(clearCart());
            if (billConfig) {
              await toast.update(toastId, {
                type: toast.TYPE.INFO,
                progress: 0.70,
                autoClose: false,
                render: `Facturación automatica para ${label}`,
              });
              setTimeout(() => {
                dispatch(fetchTimbrado(crmid));
              }, 500);
            } else if (makeBill) {
              await toast.update(toastId, {
                type: toast.TYPE.INFO,
                progress: 0.70,
                autoClose: false,
                render: `Facturación para la orden ${label}`,
              });
              setTimeout(() => {
                dispatch(fetchTimbrado(crmid));
              }, 500);
            }
            break;
        }
        await dispatch(removePricebook());
        setTimeout(() => {
          dispatch(closeModal('sell'));
          dispatch(loaded('checkout')); /* Hot FIX */
          dispatch(closeModal('checkout')); /* Hot FIX */
          dispatch(clearSell());
        }, 50);
        if (pos_print_after_sell && printConfig === null) {
          localStorage.setItem('orderTicketId', crmid);
          await toast.update(toastId, {
            type: toast.TYPE.INFO,
            progress: 0.70,
            autoClose: false,
            render: 'Impresión de Ticket ...',
          });
          setTimeout(async () => {
          //   await dispatch(changeTab('posType', 'Historico'));
            await dispatch(selectOrderAndOpenTicket(crmid));
          }, 500);
        }
        if (result.noHayDe && Object.keys(result.noHayDe).length > 0) {
          await dispatch(alertProducts(result.noHayDe));
        }

        dispatch(removeCustomer());
      }
      else {
        await toast.update(toastId, {
          type: toast.TYPE.WARNING,
          autoClose: false,
          progress: 0,
          render: message.warning || message.error,
        });

        if (error && error.code === 425) {
          // Redirect to ventas por duplicado
          dispatch(changeTab('posType', 'Historico'));
          dispatch(closeModal('checkout'));
          dispatch(openModal('errorVenta'));
          setTimeout(() => {
            toast.update(toastId, {
              type: toast.TYPE.WARNING,
              autoClose: false,
              progress: 0,
              render: 'Posible venta duplicada, por favor verifique.',
            });
            // window.location.href = '/pos/ventas';
          }, 1000);
        }
        if (error && error.code === 408) {
          // Redirect to ventas por duplicado, tiempo de espera excedido
          dispatch(changeTab('posType', 'Historico'));
          dispatch(closeModal('checkout'));
          dispatch(openModal('errorVenta'));
          setTimeout(() => {
            toast.update(toastId, {
              type: toast.TYPE.WARNING,
              autoClose: false,
              progress: 0,
              render: 'La solicitud excedió el tiempo de espera, por favor verifique.',
            });
            // redirect to ventas por duplicado
            // window.location.href = '/pos/ventas';
          }, 1000);
        } else {
          dispatch(openModal('errorVenta'));
        }
      }
    }

    catch (err) {
      toast.error('');
      await toast.update(toastId, {
        type: toast.TYPE.ERROR,
        autoClose: false,
        progress: 0,
        render: `No se pudo completar el pago debido a: ${err}`,
      });
    }
    finally {
      dispatch(loaded('sell'));
      dispatch(loaded('checkout')); /* Hot FIX */
      dispatch(loading('refreshOrders'));
      await setTimeout(() => {
        toast.dismiss(toastId);
      }, 7500);
    }
  };
}

const _fde = (type, _payments, orderId, modal, loader, all = false, showT = true, refreshList = true) => (
  async (dispatch, getState) => {
    const sellApi = new SellAPI();
    dispatch(loading(loader));
    if (showT) toast.info((type === 'I' ? ('Cobrando la orden...') : ('Devolución en proceso ...')));
    let payments = [];
    if (!all) {
      payments = _payments.map(x => ({ ...x, referencia: '' }));
    } else payments = _payments;
    const {
      authUser,
      timbrar: { makeBill },
      order: { orderData: { cvid } },
    } = getState();
    try {
      let response = {};
      if (type === 'I') {
        response = await sellApi.deliver(payments, orderId);
      }

      else if (type === 'O') {
        response = await sellApi.refund(payments, orderId);
      }
      const { success, message } = response;
      if (success) {
        if (showT) displayGroupMessage(message);
        dispatch(clearCart());
        // await dispatch(fetchAllOrders());
        if (refreshList) {
          await dispatch(fetchOrderView(cvid));
        }
        await dispatch(selectOrder(orderId));
        dispatch(closeModal(modal));
        if (type === 'I') {
          const { user: { config: { pos_checkout_bill } } } = authUser;
          const billConfig = Boolean(pos_checkout_bill === 'Siempre');
          if (billConfig) {
            toast.info('Facturación automatica para una orden...');
            setTimeout(() => {
              dispatch(fetchTimbrado(orderId));
            }, 500);
          } else if (makeBill) {
            toast.info('Facturación para una orden... ');
            setTimeout(() => {
              dispatch(fetchTimbrado(orderId));
            }, 500);
          }
        }
      }

      else {
        toast.error(message);
      }
    }

    catch {
      toast.error('Error en la API');
    }

    finally {
      dispatch(loaded(loader));
    }
  }
);

export function deliverMoney(payments, orderId) {
  return _fde('I', payments, orderId, 'checkout', 'checkout');
}

export function refundMoney(payments, orderId, all = false, showT = true, refreshList = true) {
  return _fde('O', payments, orderId, 'checkoutRefound', 'checkoutRefound', all, showT, refreshList);
}

export function saveSell() {
  // eslint-disable-next-line no-console
  return async (dispatch, getState) => {
    const sellApi = new SellAPI();
    // const guideApi = new GuideApi();
    const {
      cart,
      sell,
      discount,
      customer,
      authUser,
      filters,
      shipping,
    } = getState();
    toast.info(' 🚀 Generando Venta...');
    dispatch(loading('order'));
    try {
      const { sostatus } = sell;
      const { user: { almacenes, config: { store } } } = authUser;
      const printConfig = printerHelper.createPrintOrder(authUser.user);
      const response = await sellApi.create(cart, discount, customer, sostatus, [], false, filters, printConfig);
      const { success, message, result } = response;
      const { shippingList } = shipping;
      if (success) {
        // Create guide
        // eslint-disable-next-line no-use-before-define
        await createGuideAndProducts({
          order: result, shippingList, store, customer, almacenes,
        });
        // clear shippings
        dispatch(clearShipping());
        displayGroupMessage(message);
        await dispatch(clearCart());
        const { crmid } = result;
        const { user: { config: { pos_sell_tab } } } = authUser;
        if (pos_sell_tab) {
          await dispatch(changeTab('posType', 'Historico'));
          await dispatch(selectOrder(crmid));
        }
        if (result.noHayDe && Object.keys(result.noHayDe).length > 0) {
          await dispatch(alertProducts(result.noHayDe));
        }
      } else await displayGroupMessage(message);
    } catch (err) {
      toast.error(`Error al guardar una order: ${err}`);
    } finally {
      await dispatch(loaded('order'));
      await dispatch(removePricebook());
    }
  };
}

export function editOrder() {
  return async (dispatch, getState) => {
    const orderAPI = new OrderAPI();
    const cotizarApi = new CotizarAPI();
    const {
      cart,
      discount,
      customer,
      authUser,
      filters,
    } = getState();
    dispatch(loading('sell'));
    dispatch(loading('order'));
    dispatch(loading('product'));
    dispatch(loading('checkout'));
    try {
      const { user: { quotestage, sostatus } } = authUser;
      const { orderSelected: { status, order } } = cart;
      const isOrder = !!order?.sostatus;
      let response = {};
      if (isOrder) {
        toast.info(' 🚀 Editando Orden...');
        const orderStatus = sostatus.find(x => x.value === status);
        const {
          orderSelected: {
            order: {
              treebesdireccionfacturaid,
              treebesdireccionenvioid,
            },
          },
        } = cart;
        const facturaId = treebesdireccionfacturaid !== undefined
          ? treebesdireccionfacturaid
          : '';
        const envioId = treebesdireccionenvioid !== undefined
          ? treebesdireccionenvioid
          : '';
        const factura = facturaId.split('x').pop();
        const envio = envioId.split('x').pop();
        response = await orderAPI.editOrder(cart, discount, customer, [], orderStatus, factura, envio, filters);
      } else {
        const quoteState = Object.keys(quotestage).find(key => quotestage[key] === order.quotestage);
        toast.info(' 🚀 Editando Cotización...');
        response = await cotizarApi.edit(cart, discount, customer, [], quoteState, null, filters);
      }
      const { success, message, result } = await response;
      if (success) {
        const { crmid } = result;
        toast.success(message.success || message.warning);
        await dispatch(resetEditOrder());
        if (status) {
          await dispatch(clearCart());
          // await dispatch(fetchAllOrders());
          await dispatch(selectOrder(crmid));
          await dispatch(changeTab('posType', 'Historico'));
        } else {
          await dispatch(fetchAllCotizaciones());
          await dispatch(selectCotizacion(crmid));
          await dispatch(changeTab('posType', 'Cotizaciones'));
        }

        // dispatch(fetchAllProducts());
        if (result.noHayDe && Object.keys(result.noHayDe).length > 0) {
          await dispatch(alertProducts(result.noHayDe));
        }
      }
      else {
        toast.error(message.error);
      }
    }
    catch (e) {
      toast.error('No se completo la edición');
      console.error(e);
    }

    finally {
      dispatch(loaded('sell'));
      dispatch(loaded('order'));
      dispatch(loaded('product'));
      dispatch(loaded('checkout')); /* Hot FIX */
    }
  };
}

export function selectAddressOrder(factura = '', envio = '') {
  let toastId = null;
  return async (dispatch, getState) => {
    const orderAPI = new OrderAPI();
    dispatch(loading('address'));
    dispatch(loading('order'));
    try {
      const {
        cart,
        order: { orderData: { cvid } },
      } = getState();
      const { orderSelected: { status, order } } = cart;
      let response = {};
      if (status) {
        const msg = ' 🚀 Cambiando direcciones de la Orden...';
        toastId = toast(msg, { autoClose: false, type: toast.TYPE.INFO, progress: 0.25 });
        response = await orderAPI.updateOrderAddress(true, order.crmid, factura, envio);
      } else {
        const msg = ' 🚀 Cambiando direcciones de la Cotización...';
        toastId = toast(msg, { autoClose: false, type: toast.TYPE.INFO, progress: 0.25 });
        // response = await cotizarApi.edit(cart, discount, customer, [], factura, envio);
        response = await orderAPI.updateOrderAddress(false, order.crmid, factura, envio);
      }
      const { success, message } = await response;
      if (success) {
        await toast.update(toastId, {
          type: toast.TYPE.SUCCESS,
          autoClose: 7500,
          render: message.success || message.warning,
          progress: 1,
        });
        if (status) {
          await dispatch(fetchOrderView(cvid));
          await dispatch(selectOrder(order.crmid));
        } else {
          await dispatch(fetchAllCotizaciones());
          await dispatch(selectCotizacion(order.crmid));
        }
      } else {
        await toast.update(toastId, {
          type: toast.TYPE.WARNING,
          autoClose: 7500,
          render: message.warning || message.error,
        });
      }
      await setTimeout(() => {
        toast.dismiss(toastId);
      }, 7500);
    } catch (selectAddressOrderErr) {
      // eslint-disable-next-line no-console
      console.log({ selectAddressOrderErr });
      toast.warn(`Durante el cambio de direcciones surgio un error: ${selectAddressOrderErr}`);
    } finally {
      dispatch(loaded('address'));
      dispatch(loaded('order'));
    }
  };
}

/**
 * Deploy a mda out and a money refund for a single order
 * @param {object} refund Includes payments and crmid order
 */
export function refundAll(refund, all = false) {
  let toastId = null;
  return async (dispatch, getState) => {
    try {
      const tableApi = new TableAPI();
      let keepTable;
      let keepCart;
      let needChangeAndDeliver = false;
      await dispatch(loading('order'));
      await dispatch(closeModal('refund'));
      const { payments, crmid } = refund;
      const activity = 'Devolver Productos';
      const {
        table, order: { orderData: { cvid } }, cart, authUser,
      } = getState();
      const selection = table.items.reduce((a, el) => (a + el.field), 0);
      const msg = 'Iniciamos devolución espere un momento, favor de revisar los mensajes de cada movimiento.';
      const finalMsg = (selection > 0 && payments.length > 0 && all)
        ? (`${msg} Al finalizar, la orden se cancelará`) : (msg);
      toastId = toast(finalMsg, { autoClose: false, type: toast.TYPE.INFO, progress: 0 });

      if (table.badProducts === 'true' && table.changeProducts === 'true') {
        needChangeAndDeliver = true;
        keepTable = { ...table };
        keepCart = { ...cart };
      }

      if (selection > 0) {
        toast.update(toastId, {
          type: toast.TYPE.INFO,
          autoClose: false,
          render: 'Devolviendo movimientos de almacén ...',
          progress: 0.3,
        });
        // carga la lista de ordenes
        await dispatch(setActivityToOrder(crmid, activity, true, false));
        if (needChangeAndDeliver) {
          keepTable.badProducts = 'false';
          const printConfig = printerHelper.createPrintOrder(authUser.user);
          const response = await tableApi.setMDA(keepTable, keepCart, 'Entregar', printConfig);
          if (!response.success) {
            toast.error('No se pudieron entregar los productos');
          }
        }
      }
      if (!needChangeAndDeliver) {
        if (payments.length > 0) {
          toast.update(toastId, {
            type: toast.TYPE.INFO,
            autoClose: false,
            render: 'Devolviendo flujos de efectivo ...',
            progress: 0.6,
          });
          // carga la lista de ordenes
          await dispatch(refundMoney(payments, crmid, true, false, false));
        }
        if (selection > 0 && payments.length > 0 && all) {
          toast.update(toastId, {
            type: toast.TYPE.INFO,
            autoClose: false,
            render: 'Devolución total, cancelando orden ...',
            progress: 0.9,
          });
          await dispatch(changeOrderStatus('Cancelled', false, null, false));
        } else if (payments.length > 0 && all) {
          toast.update(toastId, {
            type: toast.TYPE.INFO,
            autoClose: false,
            render: 'Devolución total, cancelando orden ...',
            progress: 0.9,
          });
          await dispatch(changeOrderStatus('Cancelled', false, null, false));
        }
      }
      await toast.update(toastId, {
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
        render: 'Transacción Realizada',
        progress: 1,
      });
      if (!all) {
        const orderApi = new OrderAPI();
        await orderApi.trimDeliver(table.toSell);
        // carga la lista de ordenes
      }
      await dispatch(fetchOrderView(cvid));
      await dispatch(selectOrder(table.toSell));
      await setTimeout(() => {
        toast.dismiss(toastId);
      }, 3000);
      const {
        date,
        items,
        comments,
        toSell,
        store,
        ref,
      } = table;
      const ticket = {
        title: all ? 'Devolución total' : 'Devolución Parcial',
        date,
        comments,
        payments,
        from: store.name,
        to: toSell,
        mda: items,
        ref,
        client: table.account.nombre,
      };
      await dispatch(setTicket(ticket));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('err refundAll : ', err);
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

async function createGuideAndProducts({
  order, shippingList, store, customer, almacenes,
}) {
  const guideApi = new GuideApi();
  // const { authUser, customer, shipping: { shippingList } } = getState();
  // const { user: { almacenes, config: { store } } } = authUser;
  console.log('createGuideAndProducts');

  if (shippingList.length > 0) {
    shippingList.forEach(async (shipping) => {
      const createSendRequest = await guideApi.create(order, shipping, customer, shipping.packing, shipping.almacenId);

      if (createSendRequest.success) {
        let storeDirectionId = null;
        if ((!store.direccionid || store.direccionid === undefined || store.direccionid === null) && almacenes.length) {
          const storeIdx = almacenes.findIndex(alm => alm.crmid === store.crmid);
          if (storeIdx !== -1) {
            storeDirectionId = almacenes[storeIdx].treebesdireccionenvioid;
          }
        } else {
          storeDirectionId = store.direccionid;
        }
        // const storeDirId = shipping.dirEnvioId || storeDirectionId;
        await guideApi.addShippingProductRequest(createSendRequest.result.crmid, storeDirectionId, shipping.products);
      } else {
        console.log('Error al crear la guía', JSON.stringify(shipping));
      }
    });
  }
}
