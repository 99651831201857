/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect, useState } from 'react';
import 'instantsearch.css/themes/algolia-min.css';
import {
  Configure,
  InstantSearch,
} from 'react-instantsearch';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import ProductHeader from './components/product-header';
import ProductFilters from './components/product-filters';
import './post-v2.styles.scss';
import ProductContainer from './components/ProductContainer';
import ProductsSubHeader from './components/ProductsSubHeader';
import { changeMeiliIndex, removePricebook } from '../../redux/actions/priceBookActions';
import { setStorage } from '../../helpers/pricebook';
import search from '../../helpers/meili-search';

const PostV2 = React.memo(() => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.authUser.user);
  const { selectedCustomer } = useSelector(state => state.customer);
  const { config: { store }, almacenes } = user;
  const pricebookStore = useSelector(state => state.pricebook);
  const { activePriceBook, isIndexing } = pricebookStore;
  const [searchIndex, setSearchIndex] = useState(`${user.comercia_cuenta}_${activePriceBook.crmid || 0}`);

  const handleStorePriceBookChange = useCallback(() => {
    const selectedStore = almacenes.find(a => a.crmid === store.crmid);
    const comerciaCuenta = user.comercia_cuenta;
    let newIndex;

    // Prioridad para lista precio
    // 0. Lista precio seleccionada del listado
    if (activePriceBook?.crmid && activePriceBook.crmid !== '0' && !activePriceBook.isCustomer) {
      newIndex = `${comerciaCuenta}_${activePriceBook.crmid}`;
    }
    // 1. Lista precio del cliente
    else if (selectedCustomer.pricebookid && selectedCustomer.pricebookid !== '0') {
      newIndex = `${comerciaCuenta}_${selectedCustomer.pricebookid}`;
      setStorage({ crmid: selectedCustomer.pricebookid, isCustomer: true });
    }
    // 2. Lista precio de la almacen
    else if (selectedStore?.pricebookid && selectedStore.pricebookid !== '0' && selectedCustomer.pricebookid !== '0') {
      newIndex = `${comerciaCuenta}_${selectedStore.pricebookid}`;
    }
    // 3. default case
    else {
      newIndex = `${comerciaCuenta}_${activePriceBook?.crmid || 0}`;
    }

    setSearchIndex(newIndex);
    dispatch(changeMeiliIndex(newIndex));
  }, [activePriceBook, store, selectedCustomer]);

  useEffect(() => {
    handleStorePriceBookChange();
  }, [activePriceBook, store, selectedCustomer]);


  useEffect(() => {
    if (selectedCustomer.crmid === '54572') {
      dispatch(removePricebook());
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (isIndexing) {
      search.searchClient.clearCache();
    }
  }, [isIndexing]);

  useEffect(() => {
    console.log('searchIndex', searchIndex);
  }, [searchIndex]);

  return (
    <div className="pos-container">
      <div>
        <InstantSearch key={searchIndex} indexName={searchIndex} searchClient={search.searchClient}>
          <ProductHeader />
          <ProductsSubHeader searchIndex={searchIndex} />
          <ProductContainer searchIndex={searchIndex} />
          <ProductFilters />
          <Configure hitsPerPage={100} snippetEllipsisText="..." />
        </InstantSearch>
      </div>
      {
        isIndexing && (
          <div className="indexing-loader">
            <CircularProgress />
          </div>
        )
      }
    </div>
  );
});

export default PostV2;
