/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import {
  AppBar,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useLocation, useHistory } from 'react-router-dom';
// import { changeTab } from '../../redux/actions/tabActions';
import EnviosModal from '../../components/rkr2-components/modals/envios-modal/EnviosModal';
import CreateCustomerModal from '../../components/rkr2-components/modals/create-customer/CreateCustomerModal';
import SelectCustomerModal from '../../components/rkr2-components/modals/select-customer/SelectCustomerModal';
import EditCustomerModal from '../../components/rkr2-components/modals/edit-customer/EditCustomerModal';
import CamposPersonalizadosModal from '../../components/rkr2-components/modals/campos-personalizados/CamposPersonalizadosModal';
import ShareLinksModals from '../../components/rkr2-components/modals/share-links/ShareLinksModals';
import PrintOptionModal from '../../components/rkr2-components/modals/imprimibles/PrintOptionModal';
import ExternalDeliveryModal from '../../components/rkr2-components/modals/external-delivery-modal/ExternalDeliveryModal';
import LoadComponent from '../../components/Load/LoadComponent';
import Cart from '../../components/Cart';
import PostV2 from './PosV2';
import ActualOrder from '../../components/ActualOrder';
import PosItemContainerAction from '../Pos/components/PosItemContainerAction';
import { fetchListOrders, selectOrder } from '../../redux/actions/orderActions';
import CartOrderDetail from '../../components/rkr2-components/cart-order-details';
import { toggleOpenDetail } from '../../redux/actions/cartActions';
import { closeModal } from '../../redux/actions/modalActions';
import { changePosTypeTabToCotizacionesAndClearCart, changePosTypeTabToOrdersAndClearCart, changePosTypeTabToProductsAndClearCart } from '../../redux/actions/posActions';
import CartTransferModal from '../../components/rkr2-components/modals/cart-transfer-modal';
import ErrorVenta from '../../components/rkr2-components/modals/error-venta/ErrorVenta';

const MainPos = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const tabs = useSelector(state => state.tabs);
  const loads = useSelector(state => state.loads);
  const order = useSelector(state => state.order);
  const cart = useSelector(state => state.cart);
  const modals = useSelector(state => state.modals);
  const { pathname } = location;
  const { preselectOrder, enableEditOrder } = order;
  const { openDetail } = cart;
  const { cartModalIsOpen } = modals;

  // const dispatchChangeTabDisplayPosToProduct = () => dispatch(changeTab('posProduct', 'product'));
  const dispatchFetchListOrders = search => dispatch(fetchListOrders(search));
  const dispatchSelectOrder = crmid => dispatch(selectOrder(crmid));
  const dispatchToggleDetails = value => dispatch(toggleOpenDetail(value));
  const dispatchCloseCartModal = () => {
    // dispatch custom event to close cart modal
    window.dispatchEvent(new CustomEvent('closeCartModal'));
    dispatch(closeModal('cart'));
  };

  const renderOrderName = () => {
    const { orderSelected } = cart;
    const isOrder = Boolean(orderSelected?.order?.salesorder_no);
    const isQuote = Boolean(orderSelected?.order?.quote_no);
    if (isOrder) {
      return orderSelected.order?.salesorder_no === orderSelected.order?.subject
        ? orderSelected.order?.salesorder_no
        : `${orderSelected.order?.salesorder_no} - ${orderSelected.order?.subject}`;
    }
    if (isQuote) {
      return orderSelected.order?.quote_no === orderSelected.order?.subject
        ? orderSelected.order?.quote_no || orderSelected.order?.subject
        : `${orderSelected.order?.quote_no} - ${orderSelected.order?.subject}`;
    }
    return '';
  };

  /* const handleTabNavigation = () => {
    // debugger;
    const regex = /^\/pos\/ventas(\/.*)?$/;
    const isValid = regex.test(pathname);
    if (isValid) {
      tabs.posTypeTab = 'Historico';
    } else {
      switch (pathname) {
        case '/pos/ventas':
          tabs.posTypeTab = 'Historico';
          dispatch(changeTab('posProduct', 'product'));
          break;
        case '/pos/vender':
          tabs.posTypeTab = 'Vender';
          break;
        case '/pos/cotizaciones':
          tabs.posTypeTab = 'Cotizaciones';
          break;
        default:
          break;
      }
    }
  }; */

  const handleRedirect = () => {
    const regex = /^\/pos\/ventas(\/.*)?$/;
    const isValid = regex.test(pathname);
    if (isValid) {
      tabs.posTypeTab = 'Historico';
      dispatch(changePosTypeTabToOrdersAndClearCart());
    } else {
      switch (pathname) {
        case '/pos/ventas':
          history.push('/pos/ventas');
          tabs.posTypeTab = 'Historico';
          dispatch(changePosTypeTabToOrdersAndClearCart());
          break;
        case '/pos/vender':
          history.push('/pos/vender');
          tabs.posTypeTab = 'Vender';
          if (!enableEditOrder) {
            dispatch(changePosTypeTabToProductsAndClearCart());
          }
          break;
        case '/pos/cotizaciones':
          history.push('/pos/cotizaciones');
          tabs.posTypeTab = 'Cotizaciones';
          dispatch(changePosTypeTabToCotizacionesAndClearCart);
          break;
        default:
          break;
      }
    }
  };

  /*   useEffect(() => {
    // Set default tab to product
    // dispatchChangeTabDisplayPosToProduct();
    // handleRedirect();

    // Page reload listener
    window.addEventListener('beforeunload', handleRedirect);

    return () => {
      window.removeEventListener('beforeunload', handleRedirect);
    };
  }, []); */

  useEffect(() => {
    // handleTabNavigation();
    handleRedirect();
  }, [pathname]);

  useEffect(() => {
    if (preselectOrder) {
      dispatchFetchListOrders(preselectOrder.entity_label);
      dispatchSelectOrder(preselectOrder.crmid);
    }
  }, [preselectOrder]);

  return (
    <div className="pos">
      {/* <BrowserView> */}
      <Container style={{ maxWith: '100% !important' }}>
        <Grid container spacing={8} style={{ height: '100%' }}>
          <Grid item sm={tabs.posTypeTab !== 'Vender' ? 12 : 7}>
            <div>
              <PosItemContainerAction />
            </div>

            {tabs.posTypeTab === 'Vender' && <PostV2 />}
            {tabs.posTypeTab !== 'Vender' && <ActualOrder />}
          </Grid>

          {tabs.posTypeTab === 'Vender' && (
            <Grid item sm={5}>
              <section className="w-100 pos_sec_cart">
                <Cart />
              </section>
            </Grid>
          )}
        </Grid>
      </Container>

      {loads.posIsLoading && <LoadComponent />}

      {(tabs.posTypeTab === 'Historico' || tabs.posTypeTab === 'Cotizaciones') && (
        <Drawer
          anchor="right"
          open={cartModalIsOpen}
          onClose={() => {
            dispatchToggleDetails(false);
            dispatchCloseCartModal();
          }}
          PaperProps={{
            style: {
              width: openDetail ? '100%' : '50%',
              zIndex: 1050,
              transition: 'all 200ms ease 0s',
            },
          }}
          variant="persistent"
        >
          <div style={{ height: '100vh', display: 'flex', flexDirection: 'row' }}>
            <div tabIndex={0} role="button" style={{ height: '100vh', flex: 1 }}>
              <AppBar position="static" elevation={0}>
                <Toolbar
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h6" color="inherit" noWrap>
                    {renderOrderName()}
                  </Typography>
                  <div>
                    <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={() => {
                        dispatchCloseCartModal();
                        dispatchToggleDetails(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </Toolbar>
              </AppBar>
              <Cart />
            </div>
            <div
              className="o-detail"
              style={{
                flex: openDetail ? 1 : 0,
                borderLeft: '2px solid #e0e0e0',
                width: openDetail ? '100%' : 0,
                transition: 'all 200ms ease 0s',
                overflow: 'hidden',
              }}
            >
              <CartOrderDetail
                closeCart={() => {
                  dispatchToggleDetails(false);
                }}
              />
            </div>
          </div>
        </Drawer>
      )}

      {/* Modals */}
      <EnviosModal />
      <CreateCustomerModal />
      <SelectCustomerModal />
      <EditCustomerModal />
      <CamposPersonalizadosModal />
      <ShareLinksModals />
      <PrintOptionModal />
      <ExternalDeliveryModal />
      <CartTransferModal />
      <ErrorVenta />
    </div>
  );
};

export default MainPos;
