/* eslint-disable import/no-cycle */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

/* Cart Components */
import CartList from './components/CartList';
import CartTotal from './components/CartTotal';
/* Components */
import Address from '../Address';
import Fde from '../Fde';
import Files from '../Files';
import Tickets from '../Tickets';
import Timbre from '../Timbre';
import Mda from '../Mda';
import Factura from '../Factura';
import { changeSinStock } from '../../redux/actions/cartActions';


const Cart = () => {
  const dispatch = useDispatch();
  const tabs = useSelector(state => state.tabs);
  const [isCollapse, toggleCollapse] = React.useState(true);
  const cart = useSelector(state => state.cart);
  const modals = useSelector(state => state.modals);
  const { ticketsModalIsOpen } = modals;
  const { products } = cart;
  const { posTypeTab } = tabs;

  React.useLayoutEffect(() => {
    if (posTypeTab === 'Vender') {
      dispatch(changeSinStock(false));
    }
  }, [posTypeTab, products]);

  return (
    <>
      {
      ticketsModalIsOpen && localStorage.getItem('printMode') === 'true' ? null : (

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: posTypeTab === 'Vender' ? '100%' : 'calc(100% - 64px)',
            overflow: 'hidden',
          }}
        >
          <CartList />
          <CartTotal isCollapse={isCollapse} toggleCollapse={toggleCollapse} />
        </div>
      )
    }
      {/* Modals */}
      <Address />
      <Files />
      <Tickets />
      <Tickets type="factura" />
      <Tickets type="ticketsRefund" />
      <Timbre />
      <Fde />
      <Mda />
      <Factura />
    </>
  );
};

export default Cart;
