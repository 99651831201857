/* eslint-disable import/no-cycle */
import { AppBar, Button, Dialog, Slide, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { closeModals } from '../../../../redux/actions/modalActions';


const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ErrorVenta = () => {
  const dispatch = useDispatch();
  const modals = useSelector(state => state.modals);
  const history = useHistory();
  const handleClose = () => {
    dispatch(closeModals('errorVentaModalIsOpen'));
    history.push('/pos/ventas');
  };
  return (
    <Dialog
      disableEscapeKeyDown
      disableBackdropClick
      open={modals.errorVentaModalIsOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar style={{ position: 'relative', backgroundColor: '#dc3545' }}>
        <Toolbar classes={{ root: 'd-flex justify-content-center' }}>
          <Typography variant="h6" style={{ color: '#FFF' }}>
            Error al procesar la venta
          </Typography>
          <p />
        </Toolbar>
      </AppBar>
      <div className="data-container" style={{ padding: '2rem', textAlign: 'center' }}>
        <p style={{ fontSize: '1.2rem', marginBottom: '1rem' }}>
          Ha ocurrido un error al procesar la venta. Por favor, verifique que la venta se creó o intente nuevamente.
        </p>
        <Button
          style={{ backgroundColor: '#dc3545', color: '#FFF' }}
          onClick={() => {
            handleClose();
          }}
        >
          Revisar Ventas
        </Button>
      </div>
    </Dialog>
  );
};

export default ErrorVenta;
