/* eslint-disable import/no-cycle */
import {
  CHANGE_VIEW,
  SET_CART_ACTION_SELECTED,
  SET_SCAN_MODE,
} from '../actions/PosV2Actions';

const getDefaultState = () => ({
  productView: 'list',
  cartActionSelected: '',
  cartScanMode: false,
});


export default function (state = getDefaultState(), action) {
  const { type } = action;
  switch (type) {
    case CHANGE_VIEW:
      return { ...state, productView: action.payload };
    case SET_CART_ACTION_SELECTED:
      return { ...state, cartActionSelected: action.payload };
    case SET_SCAN_MODE:
      return { ...state, cartScanMode: action.payload };
    default:
      return state;
  }
}
