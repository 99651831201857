/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */

import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Comment from '../../Comment';
import {
  checkPromosAndStock,
  clearCart,
  decreaseProductInCart,
  increaseProductInCart,
  removeProductInCart,
  setDesc,
} from '../../../redux/actions/cartActions';
import {
  saveSell,
  changeSalesOrderStatus,
} from '../../../redux/actions/sellActions';
import { closeModal } from '../../../redux/actions/modalActions';
import CartListRow from './CartListRow';
import Promotions from '../../Promotions';
import CartHeader from '../../rkr2-components/cart/cart-header/CartHeader';
import OrderDescription from '../../rkr2-components/cart/cart-header/OrderDescription';

const styles = theme => ({
  paperRoot: {
    padding: 25,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    height: '55vh',
    overflowY: 'auto',
  },
  noPadding: {
    padding: 0,
    paddingTop: 15,
    paddingBottom: 15,
  },
});

const CartList = React.memo(({ classes }) => {
  const dispatch = useDispatch();
  const [rowClasses, setRowClasses] = useState({});
  const enableEditOrder = useSelector(state => state.order.enableEditOrder);
  const posTypeTab = useSelector(state => state.tabs.posTypeTab);
  const descriptionModalIsOpen = useSelector(state => state.modal?.descriptionModalIsOpen);
  const sostatus = useSelector(state => state.authUser.user.config.sostatus);
  const { products, cartProductsById, orderSelected, description } = useSelector(state => state.cart);

  /**
   * Actions
   */
  const dispatchMakeSell = (orderStatus) => {
    dispatch(changeSalesOrderStatus(orderStatus));
    dispatch(saveSell());
  };

  const dispatchRemoveProductInCart = (row) => {
    dispatch(removeProductInCart(row));
  };

  const dispatchIncreaseProductInCart = (event) => {
    const { row, count } = event.target.dataset;
    dispatch(increaseProductInCart(row, parseFloat(count) || 1));
  };

  const dispatchDecreaseProductInCart = (event) => {
    const { row } = event.target.dataset;
    dispatch(decreaseProductInCart(row));
  };

  const handleKeyTopSearchBar = useCallback(
    async (e) => {
      e.stopPropagation();
      let { keyCode } = e;
      const { ctrlKey, shiftKey, currentTarget } = e;
      keyCode = String(keyCode);
      const topSearchBar = document.querySelector('#search_bar_query');
      const active = document.activeElement;

      if (ctrlKey) {
        if (ctrlKey && keyCode === '13') {
          const checkoutButton = document.querySelector('#key_selector_button');
          // @ts-ignore
          checkoutButton.focus();
        } else if (shiftKey && keyCode === '72') {
          const currentOrderStatus = sostatus;
          await dispatchMakeSell(currentOrderStatus);
        }
      } else {
        switch (keyCode) {
          case '13':
            const isEscaped = Boolean(active.tagName === 'INPUT');
            if (topSearchBar && isEscaped) {
              e.stopPropagation();
              // @ts-ignore
              await topSearchBar.focus();
              // @ts-ignore
              // await topSearchBar.select();
            }
            break;
          case '27':
            if (topSearchBar) {
              // @ts-ignore
              await topSearchBar.focus();
              // @ts-ignore
              await topSearchBar.select();
            }
            break;
          case '46':
            const id = currentTarget.getAttribute('data-row');
            await dispatchRemoveProductInCart(id);
            break;
          case '107':
            await dispatchIncreaseProductInCart(e);
            break;
          case '109':
            await dispatchDecreaseProductInCart(e);
            break;
          default:
            break;
        }
      }
    },
    [sostatus],
  );

  /**
   * Hooks
   */
  useEffect(() => {
    if (
      posTypeTab === 'Vender'
      && Object.keys(orderSelected).length !== 0
      && !enableEditOrder
    ) {
      dispatch(clearCart());
    }
  }, [posTypeTab, orderSelected, enableEditOrder]);

  useEffect(() => {
    let newRowClasses = {};
    const oldCartProductsById = cartProductsById;

    products.forEach((product) => {
      const { crmid } = product;

      if (!(crmid in rowClasses)) {
        newRowClasses = {
          ...newRowClasses,
          [crmid]: 'tRow-fix animated pulse',
        };
      } else {
        newRowClasses = {
          ...newRowClasses,
          [crmid]: 'tRow-fix animated slideInDown',
        };
      }

      if (crmid in oldCartProductsById) {
        const oldProduct = oldCartProductsById[crmid];
        if (oldProduct.quantity !== product.quantity) {
          newRowClasses = {
            ...newRowClasses,
            [crmid]: 'tRow-fix animated pulse',
          };
        }
      }
    });

    setRowClasses(newRowClasses);
  }, [products]);

  return (
    <div className="cartListCollapse">
      <div className="cart-state-__container">
        <CartHeader />
      </div>
      <OrderDescription />
      <Table className="cart_list_table">
        <TableHead>
          <TableRow style={{ height: '24px' }}>
            <TableCell align="center" style={{ width: '20vw' }}>
              Nombre
            </TableCell>
            <TableCell align="center">Cantidad</TableCell>
            <TableCell align="center">Precio</TableCell>
            {products.some(product => product.discount_percent > 0) && (
              <TableCell align="center">Descuento</TableCell>
            )}
            <TableCell align="center">Total</TableCell>
            <TableCell align="center" style={{ width: '8vw' }} />
          </TableRow>
        </TableHead>
      </Table>
      <div id="overflow-comercia" className="w-100">
        <Table className="cart_list_table">
          <TableBody>
            {products.map(product => (
              <CartListRow
                key={`CartList-${product.parentId}-${product.crmid}-${product.row}`}
                classes={classes}
                product={product}
                rowClasses={rowClasses}
                handleKeyTopSearchBar={handleKeyTopSearchBar}
                dispatchIncreaseProductInCart={dispatchIncreaseProductInCart}
                dispatchDecreaseProductInCart={dispatchDecreaseProductInCart}
                dispatchRemoveProductInCart={dispatchRemoveProductInCart}
              />
            ))}
          </TableBody>
        </Table>
        <Table className="cart_list_table">
          <TableBody>
            <Promotions />
          </TableBody>
        </Table>
      </div>
      <Comment
        title="Descripción de la Orden"
        close={() => dispatch(closeModal('description'))}
        isOpen={Boolean(descriptionModalIsOpen || undefined)}
        description={description}
        sendDesc={desc => dispatch(setDesc(desc))}
        enableEditOrder={
          orderSelected && orderSelected.order
            ? enableEditOrder
            : true
        }
      />
    </div>
  );
});

CartList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CartList);
