/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Menu,
  Badge,
  Input,
  Popover,
  Tooltip,
  MenuItem,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  Book,
  MoreVert,
  Bookmarks,
  DeleteForever,
  CommentOutlined,
  Label, LabelOutlined,
} from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Percentage from '../../Discount/_components/constructor/components/mask/PercentageMask';
import StockModal from '../../Stock/stockModal/StockModal';
import SeeOnCrmAction from './controls/components/SeeOnCrmAction';
import AppliedPromotionsAction from './controls/components/AppliedPromotionsAction';
import CodigoBarraModal from '../../rkr2-components/modals/codigo-barra/CodigoBarraModal';
import { updateProductBarcode } from '../../../redux/actions/cartActions';
import ProductLocationModal from '../../rkr2-components/modals/product-location/ProductLocationModal';

const CartListOptions = ({
  commentZone,
  dispatchRemoveProductInCart,
  dispatchSetSingleDiscount,
  edit,
  getPriceByPricebook,
  product,
  setCommentZone,
  user,
}) => {
  const dispatch = useDispatch();
  /* Hooks */
  const [anchoerEl, setAnchorEl] = React.useState();
  const [singleDiscount, setSingleDiscount] = React.useState(0);
  const [discountAnchor, setDiscountAnchor] = React.useState(null);
  const [pricebookAchor, setPricebookAnchor] = React.useState();
  const [openStock, setOpenStock] = React.useState(false);
  /* Const */
  const open = Boolean(anchoerEl);
  const openPop = Boolean(discountAnchor);
  const openPrice = Boolean(pricebookAchor);
  const arePricebooks = Boolean(user.pricebooks_ref && user.pricebooks_ref.length > 0);
  // const arePricebooks = Boolean(user.PriceBooks && user.PriceBooks.length > 0);
  /* Set */
  const idPop = openPop ? 'discount-popover' : undefined;
  const idPrice = openPrice ? 'pricebook-popover' : undefined;
  const inventario = Boolean((user.almacenes && user.almacenes.length > 1) || (user.TreebesALM_public_inventory && user.TreebesALM_public_inventory.length > 1));

  const config = useSelector(state => state.authUser.user.config);
  const { pos_no_descuentos } = config;
  const canApplyDiscount = Boolean(pos_no_descuentos === '1');

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  function setSingleDiscountOnChange(e) {
    const { target: { value } } = e;
    const percentage = (parseFloat(value) || 0);
    if (percentage >= 0 && percentage <= 100) {
      setSingleDiscount(percentage);
    }
  }
  function setSingleDiscountOnBlur() {
    const { row } = product;
    const percentage = singleDiscount;
    console.log('percentage', percentage);
    console.log('row', row);
    dispatchSetSingleDiscount(row, percentage);
  }
  function setSingleDiscountKeyPress(e) {
    const { charCode } = e;
    if (charCode === 13) {
      setDiscountAnchor(null);
    }
  }
  /* Inventario */
  function openStockDialog() {
    setOpenStock(true);
  }
  function handleCloseStock() {
    setOpenStock(false);
  }

  function updateBarCode(producto, barCode) {
    console.log('updateBarCode', producto, barCode);
    dispatch(updateProductBarcode(producto, barCode));
  }

  function updateProductLocation(producto, location) {
    console.log('updateProductLocation', producto, location);
    //dispatch(updateProductBarcode(producto, location));
  }

  return (
    <>
      <IconButton
        aria-label="options"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="row-menu"
        anchorEl={anchoerEl}
        open={open}
        onClose={handleClose}
      >
        {/* Eliminar producto */}
        {edit && (
          <MenuItem onClick={() => dispatchRemoveProductInCart(product.row)}>
            <Tooltip title={`Quitar : ${product.productname}`}>
              <IconButton>
                <DeleteForever />
              </IconButton>
            </Tooltip>
          </MenuItem>
        )}
        {/* Aplicar descuento */}
        {edit && (
          <MenuItem
            disabled={canApplyDiscount}
            onClick={(e) => {
              setDiscountAnchor(e.currentTarget);
            }}
          >
            <Tooltip title="Aplicar descuento">
              <IconButton>
                <FontAwesomeIcon
                  icon="percent"
                  style={{ fontSize: '0.9em' }}
                />
              </IconButton>
            </Tooltip>
          </MenuItem>
        )}
        {/* Lista de precios */}
        {edit && (
          <MenuItem
            onClick={(e) => {
              setPricebookAnchor(e.currentTarget);
            }}
          >
            <Tooltip title="Precio de lista de precios">
              <IconButton>
                <Book />
              </IconButton>
            </Tooltip>
          </MenuItem>
        )}
        {/* Inventarios */}
        {inventario && (
          <MenuItem
            onClick={() => {
              openStockDialog();
            }}
          >
            <Tooltip title="Mostrar Inventario">
              <IconButton>
                <FontAwesomeIcon
                  icon="boxes"
                  style={{ fontSize: '0.9em' }}
                />
              </IconButton>
            </Tooltip>
          </MenuItem>
        )}
        {/* Comentarios */}
        <MenuItem
          onClick={async () => {
            await handleClose();
            await setCommentZone(!commentZone);
          }}
        >
          {edit ? (
            <Tooltip title={`Comentarios: ${product.comment}`}>
              <IconButton>
                <Badge
                  color="secondary"
                  variant="dot"
                  invisible={product.comment === ''}
                >
                  <CommentOutlined
                    color={commentZone ? 'primary' : 'inherit'}
                  />
                </Badge>
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={`Ver comentarios: ${product.comment}`}>
              <IconButton
                disabled={
                  product.comment === '' || product.comment === undefined
                }
              >
                <Badge
                  color="primary"
                  variant="dot"
                  invisible={
                    product.comment === '' || product.comment === undefined
                  }
                >
                  <CommentOutlined
                    color={commentZone ? 'primary' : 'inherit'}
                  />
                </Badge>
              </IconButton>
            </Tooltip>
          )}
        </MenuItem>
        <MenuItem>
          <SeeOnCrmAction enable appLocation="product" tooltip="Ver producto en sistema" product={product} />
        </MenuItem>
        <MenuItem>
          <AppliedPromotionsAction product={product} />
        </MenuItem>
        <MenuItem>
          <CodigoBarraModal product={product} updateBarCode={updateBarCode} />
        </MenuItem>
        <MenuItem>
          <ProductLocationModal product={product} updateProductLocation={updateProductLocation} />
        </MenuItem>

      </Menu>
      <Popover
        id={idPop}
        open={openPop}
        anchorEl={discountAnchor}
        onClose={() => setDiscountAnchor(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <div className="p-1">
          <Input
            style={{
              textAlign: 'right',
              width: '50px',
            }}
            autoFocus
            //inputComponent={Percentage}
            defaultValue="Descuento %"
            inputProps={{
              'aria-label': 'description',
            }}
            value={singleDiscount}
            step="0.01"
            type="number"
            max={100}
            onBlur={setSingleDiscountOnBlur}
            onChange={setSingleDiscountOnChange}
            onKeyPress={setSingleDiscountKeyPress}
          />
        </div>
      </Popover>
      <Popover
        id={idPrice}
        open={openPrice}
        anchorEl={pricebookAchor}
        onClose={() => setPricebookAnchor(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        {!arePricebooks && (
          <List>
            <ListItem button disabled dense>
              <ListItemIcon>
                <LabelOutlined />
              </ListItemIcon>
              <ListItemText primary="Sin listas de precios" />
            </ListItem>
          </List>
        )}
        {arePricebooks && (
          <List>
            <ListItem
              button
              dense
              onClick={() => {
                setPricebookAnchor(null);
                getPriceByPricebook(product.row);
              }}
            >
              <ListItemIcon>
                <Bookmarks />
              </ListItemIcon>
              <ListItemText primary="Precio base" />
            </ListItem>
            {/* {user.pricebooks_ref.map(item => ( */}
            {user.PriceBooks.map(item => (
              <ListItem
                key={`key_product_pricebook_${item.crmid}`}
                button
                dense
                onClick={() => {
                  setPricebookAnchor(null);
                  getPriceByPricebook(product.row, item.crmid);
                }}
              >
                <ListItemIcon>
                  <Label />
                </ListItemIcon>
                <ListItemText primary={item.entity_label} />
              </ListItem>
            ))}
          </List>
        )}
      </Popover>
      <Dialog
        open={openStock}
        onClose={handleCloseStock}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Inventario</DialogTitle>
        <DialogContent>
          <StockModal product={product} stocks={user.TreebesALM_public_inventory} />
        </DialogContent>
      </Dialog>
    </>
  );
};

CartListOptions.propTypes = {
  commentZone: PropTypes.bool.isRequired,
  dispatchRemoveProductInCart: PropTypes.func.isRequired,
  dispatchSetSingleDiscount: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  getPriceByPricebook: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  setCommentZone: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default CartListOptions;
