/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import CheckOutModal from '../../../CheckOut/_components/CheckOutModal';
import { closeModal, openModal } from '../../../../redux/actions/modalActions';
import { setOrderCustomer } from '../../../../redux/actions/orderActions';

import { handleMakeBill } from '../../../../redux/actions/TimbrarActions';
import GuideApi from '../../../../api/GuideApi';
import DropUpButton from '../DropUpButton';

const CartCobrarBtn = ({
  title, onSave, modalTitle, totalToPay,
}) => {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const customer = useSelector(state => state.customer);
  const modals = useSelector(state => state.modals);
  const loads = useSelector(state => state.loads);
  const authUser = useSelector(state => state.authUser);
  const timbrar = useSelector(state => state.timbrar);
  const deliver = useSelector(state => state.shipping?.shippingList);

  const { defaultCustomer, selectedCustomer } = customer;
  const { orderSelected, sinStock } = cart;
  const { makeBill } = timbrar;
  const orderStatuses = authUser.user.sostatus;
  const paymentTypes = authUser.user.metodos_pagos;

  const {
    user: {
      almacenes,
      config: {
        sostatus, pos_checkout_bill, store, pos_sininv, pos_no_cobrar,
      },
      shipping_id,
    },
  } = authUser;
  const selectedStore = almacenes.find(almacen => almacen.crmid === store.crmid);
  const noCobrar = Boolean(pos_no_cobrar === '1');
  const canSell = cart.totalProducts > 0 && orderSelected?.status !== 'Cancelado';
  const canUserSell = noCobrar ? true : !canSell;

  const guideApi = new GuideApi();

  function checkSinStock() {
    if (selectedStore?.pos_sininv === '1') {
      return false;
    }

    if (pos_sininv === '1') {
      return false;
    }
    if (!sinStock) {
      return canUserSell;
    }
    return true;
  }

  function openCheckoutModal() {
    dispatch(openModal('checkout'));
  }

  function editOrder(account_id) {
    dispatch(setOrderCustomer(account_id));
  }

  function dispatchHandleMakeBill(mode) {
    dispatch(handleMakeBill(mode));
  }

  const openLocalModal = async () => {
    if (selectedCustomer.crmid === defaultCustomer.crmid) {
      openCheckoutModal();

      return;
    }
    // Total products
    const productQty = cart.products
      .filter(p => p.crmid !== shipping_id)
      .reduce((acc, prod) => acc + prod.quantity, 0);
    // Total products with shipping
    const shippingProducts = [];
    deliver.map(p => p.products.map(prod => shippingProducts.push(prod)));
    const totalShippingProducts = shippingProducts.reduce(
      (acc, prod) => acc + prod.quantity,
      0,
    );

    if (store.forzaEnvio === '1') {
      const shippingIsSelected = cart.products.findIndex((prod) => {
        let productid = '';
        if (prod.crmid) {
          productid = prod.crmid && prod.crmid.indexOf('x') !== -1
            ? prod.crmid.split('x').pop()
            : prod.crmid;
        } else {
          productid = prod.productid && prod.productid.indexOf('x') !== -1
            ? prod.productid.split('x').pop()
            : prod.productid;
        }

        return productid === shipping_id;
      });

      // if is Order get guides
      if (orderSelected && orderSelected.order) {
        toast.info('Verificando envíos...');
        const response = await guideApi.getOrderGuides(orderSelected.crmid);
        const { success, result } = response;
        if (!result.length && success) {
          toast.info('Debes seleccionar un envío antes de cobrar');
          dispatch(openModal('shipping'));
          return;
        }
      } else if (
        shippingIsSelected === -1
        && (deliver.length === 0 || totalShippingProducts !== productQty)
      ) {
        if (totalShippingProducts !== productQty) {
          toast.info(
            'La cantidad de los productos en envíos debe ser la misma cantidad de productos que la orden',
          );
          dispatch(openModal('shipping'));
          return;
        }

        if (Object.keys(orderSelected).length > 0) {
          const response = await guideApi.getOrderGuides(orderSelected.crmid);
          const { success, result } = response;
          if (!result.length && success) {
            toast.info('Debes seleccionar un envío antes de cobrar');
            dispatch(openModal('shipping'));
            return;
          }
        } else {
          toast.info('Debes seleccionar un envío antes de cobrar');
          dispatch(openModal('shipping'));
          return;
        }
      } else if (
        shippingIsSelected !== -1
        && totalShippingProducts !== productQty
      ) {
        toast.info(
          'La cantidad de los productos en envíos debe ser la misma cantidad de productos que la orden',
        );
        dispatch(openModal('shipping'));
        return;
      }
    }
    openCheckoutModal();
  };

  return (
    <>
      <Tooltip
        placement="bottom"
        open={false}
        title="CTRL + ENTER para realizar cobro"
        disableFocusListener
        disableHoverListener
        disableTouchListener
        PopperProps={{
          disablePortal: true,
        }}
      >
        <div className="w-100 h-100">
          <DropUpButton
            title={title}
            icon={faHandHoldingUsd}
            onClick={cart.totalProducts > 0 ? openLocalModal : () => {}}
            /* disabled={pos_sininv ? false : !sinStock ? canUserSell : true} */
            disabled={checkSinStock()}
          />
        </div>
      </Tooltip>

      <CheckOutModal
        cart={cart}
        customer={customer}
        defaultOrderStatus={sostatus}
        dispatchEditOrder={editOrder}
        handleMakeBill={dispatchHandleMakeBill}
        isLoading={Boolean(loads.checkoutIsLoading)}
        isOpen={Boolean(modals.checkoutModalIsOpen)}
        makeBill={makeBill}
        onSave={onSave}
        orderStatuses={orderStatuses}
        paymentTypes={paymentTypes}
        posCheckoutBill={pos_checkout_bill}
        title={modalTitle}
        toggle={() => dispatch(closeModal('checkout'))}
        totalToPay={totalToPay}
      />
    </>
  );
};

export default CartCobrarBtn;
