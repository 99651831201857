/* eslint-disable camelcase */

import fetch from 'cross-fetch';
import { v4 as uuidv4 } from 'uuid';
import ConfigurationAPI from './ConfigurationAPI';

class SellAPI extends ConfigurationAPI {
  create = async (
    cart,
    discount,
    customer,
    sostatus,
    payments,
    deliver = false,
    filters,
    printConfig = null,
    convertRate = 1,
  ) => {
    const { discountTaxes } = filters;
    const totalCart = cart.total/* * convertRate */;
    const totalDiscount = discount.total/* * convertRate */;
    const totalToPay = Number((totalCart - totalDiscount).toFixed(2));
    const { promotions, orderSelected } = cart;
    const isQuote = !!(orderSelected?.order && orderSelected?.order.hasOwnProperty('quote_no'));

    const products = [];
    const treebeslotes = {};
    cart.products.forEach((product) => {
      const productid = product.productid && product.productid.indexOf('x') !== -1
        ? product.productid.split('x').pop()
        : product.productid;
      const custom = {
        option: [
          {
            product_option_value_id: product.crmid || productid,
          },
        ],
        discount_percent: product.discount_percent,
        price: (product.priceWithTaxes /* * convertRate */) / (1 - product.discount_percent / 100),
        pu: product.unit_price /* * convertRate */,
        product_id: product.parentId,
        quantity: product.quantity,
        productid: product.crmid || productid,
        comment: product.comment,
      };
      products.push(custom);
      if (product.series && product.series.length) {
        treebeslotes[product.crmid] = {
          id: [],
          number: [],
          quantity: [],
        };

        product.series.forEach((serie) => {
          treebeslotes[product.crmid].id.push(serie.treebeslotesid);
          treebeslotes[product.crmid].number.push(serie.label);
          treebeslotes[product.crmid].quantity.push(1);
        });
      }
    });

    /**
     * Working on promotions
     */
    if (promotions && promotions.free_products) {
      promotions.free_products.forEach((product) => {
        const promotionProduct = {
          option: [
            {
              product_option_value_id: product.productid,
            },
          ],
          discount_percent: 0,
          price: product.unit_price /* * convertRate */,
          pu: product.unit_price /* * convertRate */,
          product_id: null,
          quantity: product.quantity,
          productid: product.productid,
          comment: '',
        };
        products.push(promotionProduct);
      });
    }

    const data = {
      products,
      sin_impuestos: discountTaxes ? 1 : 0,
      subtotal: cart.subTotal /* * convertRate */,
      total: totalToPay,
      account_no: customer.selectedCustomer.account_no,
      tpv_payment: payments,
      tpv_discount_amount: discount.fixed,
      tpv_discount_percentage: discount.percentage,
      order_status: sostatus,
      comment: cart.description,
      treebesdireccionenvioid:
        customer.selectedCustomer.treebesdireccionenvioid,
      treebesdireccionfacturaid:
        customer.selectedCustomer.treebesdireccionfacturaid,
      deliver,
      TreebesPromociones_Aplicadas:
        promotions && promotions.applied_promos
          ? Object.keys(promotions.applied_promos)
          : [],
      treebeslotes: payments && payments.length && Object.keys(treebeslotes).length ? treebeslotes : {},
    };

    if (isQuote) {
      data.quote_id = orderSelected.crmid;
    }

    if (!isQuote) {
      let invalidPayment = false;
      const paymentErrorResponse = {
        success: false,
        message: 'Error en los datos de pago',
      };
      // Validate payents
      for (let i = 0; i < payments.length; i += 1) {
        if (payments[i].monto < 0) {
          invalidPayment = true;
          paymentErrorResponse.message = 'Error en el monto de pago';
          break;
        }

        if (!payments[i].metodo) {
          invalidPayment = true;
          paymentErrorResponse.message = 'Error en el metodo de pago';
          break;
        }

        if (!payments[i].tcybid) {
          invalidPayment = true;
          paymentErrorResponse.message = 'Error en la referencia de pago';
          break;
        }
      }

      if (invalidPayment) {
        return paymentErrorResponse;
      }
    }

    // Checking print configuration
    if (printConfig && printConfig.print_templateid && printConfig.print_impresoraid) {
      data.print_templateid = printConfig.print_templateid;
      data.print_impresoraid = printConfig.print_impresoraid;
    }

    this.credentials.method = 'POST';
    // this.credentials.headers['currency-id'] = localStorage.getItem('currencyId');
    this.credentials.headers['prevent-duplicate'] = uuidv4();
    this.credentials.body = JSON.stringify(data);
    let response;
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 150000); // 2.5 minute timeout

      response = await fetch(`${this.url}/SalesOrder`, {
        ...this.credentials,
        signal: controller.signal,
      });

      clearTimeout(timeoutId);

      const json = await response.json();
      return json;
    } catch (err) {
      if (err.name === 'AbortError') {
        return {
          success: false,
          error: {
            code: 408,
          },
          message: 'La solicitud excedió el tiempo de espera',
        };
      }
      return {
        success: false,
        status: response?.status,
        message: err.message,
      };
    }
  };

  _fde = async (type, payments, related_to) => {
    let invalidPayment = false;
    const paymentErrorResponse = {
      success: false,
      message: 'Error en los datos de pago',
    };
      // Validate payents
    for (let i = 0; i < payments.length; i += 1) {
      if (payments[i].monto < 0) {
        invalidPayment = true;
        paymentErrorResponse.message = 'Error en el monto de pago';
        break;
      }

      if (!payments[i].metodo) {
        invalidPayment = true;
        paymentErrorResponse.message = 'Error en el metodo de pago';
        break;
      }

      if (!payments[i].tcybid) {
        invalidPayment = true;
        paymentErrorResponse.message = 'Error en la referencia de pago';
        break;
      }
    }

    if (invalidPayment) {
      return paymentErrorResponse;
    }
    const data = {
      type,
      flow: payments,
      related_to,
    };

    this.credentials.method = 'POST';
    this.credentials.body = JSON.stringify(data);

    try {
      const response = await fetch(`${this.url}/TreebesFdE`, this.credentials);
      const json = await response.json();
      const information = json.success ? 'Envio con exito' : json.error.message;
      const message = { ...json.message, information };
      return { ...json, message };
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  };

  deliver = async (payments, related_to) => {
    const result = await this._fde('I', payments, related_to);
    return result;
  };

  refund = async (payments, related_to) => {
    const result = await this._fde('O', payments, related_to);
    return result;
  };

  evoPayment = async (
    posId,
    amount,
    traceId,
  ) => {
    this.credentials.method = 'POST';

    const data = {
      traceId,
      amount: `${amount}`,
    };

    this.credentials.body = JSON.stringify(data);

    const response = await fetch(
      `${this.url}/EvoPayments/${posId}/Sale `,
      this.credentials,
    );
    try {
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        success: false,
        status: response.status,
        message: response.error.message,
      };
    }
  };

  evoPaymentDetail = async (transactionId, posId) => {
    const url = `${this.url}/EvoPayments/${posId}/Details?txnid=${transactionId}`;
    try {
      const response = await fetch(url, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch (getErr) {
      return {
        success: false,
        result: [],
        message: `Error al crear una dirección: ${getErr}`,
      };
    }
  }
}

export default SellAPI;
