/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { ToastContainer } from 'react-toastify';
import { library } from '@fortawesome/fontawesome-svg-core';
import { createBrowserHistory } from 'history';
import {
  faCaretDown,
  faCartArrowDown,
  faCashRegister,
  faCoins,
  faCreditCard,
  faDolly,
  faEquals,
  faFile,
  faFileImage,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFileSignature,
  faFilter,
  faFolderOpen,
  faDollarSign,
  faHandHoldingUsd,
  faHandPointRight,
  faInfo,
  faInfoCircle,
  faMapMarkerAlt,
  faMinus,
  faMoneyBillWave,
  faMoneyCheck,
  faPercent,
  faPlus,
  faSearch,
  faShoppingCart,
  faSignInAlt,
  faSignOutAlt,
  faStore,
  faThLarge,
  faThList,
  faTrashAlt,
  faUser,
  faUserCog,
  faUserPlus,
  faUserTimes,
  faWeightHanging,
  faWarehouse,
  faShareAlt,
  faPencilAlt,
  faChevronLeft,
  faPen,
  faBoxes,
  faLink,
  faHome,
  faEye,
  faGift,
  faPenSquare,
  faTruck,
  faCertificate,
  faClipboardCheck,
  faPlay,
  faStop,
  faFunnelDollar,
  faFileDownload,
  faTrophy,
  faBarcode,
  faSearchLocation,
  faComment,
} from '@fortawesome/free-solid-svg-icons';

import '../../scss/app.scss';
import Router from '../../router';
import store from '../../redux/store';
import ScrollToTop from '../../components/ScrollToTop';
import LoadComponent from '../../components/Load/LoadComponent';

export const history = createBrowserHistory();

library.add(
  faCaretDown,
  faCartArrowDown,
  faCashRegister,
  faCoins,
  faCreditCard,
  faDolly,
  faEquals,
  faFile,
  faFileImage,
  faFileInvoice,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFileSignature,
  faFilter,
  faFolderOpen,
  faDollarSign,
  faHandHoldingUsd,
  faHandPointRight,
  faInfo,
  faInfoCircle,
  faMapMarkerAlt,
  faMinus,
  faMoneyBillWave,
  faMoneyCheck,
  faPercent,
  faPlus,
  faSearch,
  faShoppingCart,
  faSignInAlt,
  faSignOutAlt,
  faStore,
  faThLarge,
  faThList,
  faTrashAlt,
  faUser,
  faUserCog,
  faUserPlus,
  faUserTimes,
  faWeightHanging,
  faWarehouse,
  faShareAlt,
  faPencilAlt,
  faChevronLeft,
  faPen,
  faBoxes,
  faLink,
  faHome,
  faEye,
  faGift,
  faPenSquare,
  faTruck,
  faCertificate,
  faClipboardCheck,
  faPlay,
  faStop,
  faFunnelDollar,
  faFileDownload,
  faTrophy,
  faBarcode,
  faSearchLocation,
  faComment,
);

const comerciaTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#23a1db',
      light: '#70bbfd',
      dark: '#009cff',
      contrastText: '#fff',
    },
    secondary: {
      main: '#8152a0',
      light: '#7f50a0',
      dark: '#8152a0',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class App extends Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
    };
    if (window.performance) {
      if (performance.navigation.type === 1) {
        localStorage.setItem('productFilters', '');
      }
    }
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    // isMobile ? window.location.href = 'https://m.comercia.io' : null;
    window.addEventListener('load', () => {
      this.setState({ loaded: true });
    });
  }

  render() {
    const { loaded } = this.state;
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={comerciaTheme}>
          <BrowserRouter basename="/">
            <ScrollToTop>
              <>
                { !loaded && <LoadComponent /> }
                <Router history={history} />
                <ToastContainer
                  position="bottom-left"
                  // autoClose={2500}
                  hideProgressBar={false}
                  newestOnTop={false}
                  rtl={false}
                  closeOnClick
                  pauseOnVisibilityChange
                  draggable
                  pauseOnHover
                />
              </>
            </ScrollToTop>
          </BrowserRouter>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
