/* eslint-disable semi */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const TriggerType = Object.freeze({
  MENU_ITEM_ICON: 'MenuItemIcon',
  BUTTON: 'button',
  LIST_ITEM: 'ListItem',
});

const ProductCommentModal = ({
  product = null, updateComment = () => {}, triggerType = TriggerType.MENU_ITEM_ICON,
}) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const inputRef = React.useRef(null);
  console.log(product);

  const addComment = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendComment = async () => {
    setIsLoading(true);
    updateComment(product, inputRef.current.value);
    setIsLoading(false);
  };

  useEffect(() => {
    if (open && product?.comment && inputRef.current) {
      inputRef.current.value = product.comment;
      inputRef.current.focus();
    }
  }, [product, open]);

  return (
    <>
      {triggerType === TriggerType.BUTTON && (
      <button
        onClick={addComment}
        style={{
          border: '1px solid gray',
          borderRadius: 10,
          width: '100%',
          marginTop: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 25,
          backgroundColor: 'transparent',
        }}
        type="button"
      >
        <FontAwesomeIcon icon="comment" style={{ marginRight: 8 }} />
        {product && product.comment ? 'Cambiar comentario' : 'Agregar comentario'}
      </button>
      )}

      {triggerType === TriggerType.MENU_ITEM_ICON && (
        <Tooltip
          title={product && product.comment ? 'Cambiar comentario' : 'Agregar comentario'}
          placement="left"
        >
          <span>
            <IconButton
              id="action_control_see"
              onClick={addComment}
              className="pos_menu_setDir"
            >
              <FontAwesomeIcon
                icon="comment"
                style={{ width: '21px', heigth: '21px' }}
              />
            </IconButton>
          </span>
        </Tooltip>
      )}

      {/* Modal */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="simple-dialog-title">
        <DialogTitle id="simple-dialog-title">
          {product && product.comment ? 'Cambiar comentario' : 'Agregar comentario'}
        </DialogTitle>
        <DialogContent>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
          }}
          >
            <div>
              <p style={{ fontSize: 15 }}>
                Producto:
              </p>
              <p style={{ fontSize: 14 }}>
                {product?.producto || product.productname}
              </p>
            </div>
            <div>
              <p style={{ fontSize: 15 }}>Comentario:</p>
              <div style={{
                border: '1px solid #d8d8d8',
                borderRadius: 5,
                display: 'flex',
                overflow: 'hidden',
              }}
              >
                <input
                  style={{
                    width: '100%',
                    height: 36,
                    border: 0,
                    paddingLeft: 10,
                    outline: 'none',
                  }}
                  defaultValue={product?.comment}
                  disabled={isLoading}
                  ref={inputRef}
                  type="text"
                  placeholder="Agrega el comentario"
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {
            isLoading ? (
              <Collapse in style={{ width: '100%' }}>
                <LinearProgress />
              </Collapse>
            ) : (
              <>
                <Button onClick={handleClose}>
                  Cancelar
                </Button>
                <Button onClick={sendComment} color="primary">
                  {product && product.comment ? 'Cambiar' : 'Agregar'}
                </Button>
              </>
            )
          }
        </DialogActions>
      </Dialog>
    </>
  );
}
ProductCommentModal.propTypes = {
  product: PropTypes.object.isRequired,
  updateComment: PropTypes.func.isRequired,
  triggerType: PropTypes.string.isRequired,
};

export default ProductCommentModal;
