/* eslint-disable import/no-cycle */
import { debounce } from 'lodash';
import {
  ADD_DIRECT_PRODUCT_TO_CART,
  ADD_PRODUCT_TO_CART,
  ADD_PRODUCT_TO_CART_QTY,
  DECREASE_PRODUCT_IN_CART,
  INCREASE_PRODUCT_IN_CART,
  UPDATE_PRODUCT_QTY_IN_CART,
  updatePromotions,
  verifyProductStock,
} from '../actions/cartActions';
import { ADD_PRICEBOOK } from '../actions/priceBookActions';

const verifyStockAndPromotions = debounce(async (dispatch) => {
  await Promise.all([
    dispatch(updatePromotions()),
    dispatch(verifyProductStock()),
  ]);
}, 1000); // 500ms de debounce

const cartMiddleware = ({ dispatch }) => next => (action) => {
  // Filtra las acciones que afectan al carrito
  if (action.type === ADD_DIRECT_PRODUCT_TO_CART
    || action.type === ADD_PRODUCT_TO_CART
    || action.type === ADD_PRODUCT_TO_CART_QTY
    || action.type === INCREASE_PRODUCT_IN_CART
    || action.type === DECREASE_PRODUCT_IN_CART
    || action.type === UPDATE_PRODUCT_QTY_IN_CART
  ) {
    next(action); // Propaga la acción al reducer

    // Ejecuta la verificación con debounce
    verifyStockAndPromotions(dispatch);
  } else if (action.type === ADD_PRICEBOOK) {
    // Update promotions and verify cart prices based on the pricebook
    next(action);
    dispatch(updatePromotions());
  } else {
    next(action); // Propaga las demás acciones sin modificaciones
  }
};

export default cartMiddleware;
