/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async/';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import CustomerAPI from '../../../api/CustomerAPI';
import {
  selectCustomer,
} from '../../../redux/actions/customerActions';

import { handleEditOrder } from '../../../redux/actions/orderActions';
import { updateProductsInCartWhenChangePriceBook, updatePromotions } from '../../../redux/actions/cartActions';
import { pricebookHelper } from '../../../helpers';

let timeout = null;

const SearchCustomer = ({
  customer,
  single,
  control,
  orderSelected,
  dispatchEditOrder,
  dispatchSelectCustomer,
}) => {
  const dispatch = useDispatch();
  const [activePriceBook, setActivePriceBook] = useState(null);
  const { customers, selectedCustomer } = customer;
  const [customerSelection, setCustomerSelection] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const pricebook = useSelector(state => state.pricebook);
  const [newSelectedCustomer, setNewSelectedCustomer] = React.useState(null);
  const enableEditOrder = useSelector(state => state.order.enableEditOrder);
  const activeTab = useSelector(state => state.tabs.posTypeTab);
  const authUser = useSelector(state => state.authUser);
  const { user: { pricebooks_ref, PriceBooks, config: { pos_can_change_price } } } = authUser;
  const { meiliIndex } = pricebook;
  const priceBookList = pricebooks_ref || PriceBooks || [];

  const setCustomer = (_customer) => {
    setCustomerSelection(
      {
        id: _customer.crmid,
        label: _customer.accountname,
      },
    );
    // Get pricebook from storage
    const pricebook = pricebookHelper.getStorage();
    if (_customer.pricebookid !== pricebook?.crmid) {
      pricebookHelper.setStorage({
        crmid: _customer.pricebookid,
        entity_label: _customer.pricebookid_label,
        isCustomer: true,
      });
    }
  };

  React.useEffect(() => {
    if (selectedCustomer !== undefined) {
      setCustomer(selectedCustomer);
    }
  }, [selectedCustomer]);


  React.useEffect(() => {
    if (meiliIndex) {
      const indexId = meiliIndex.split('_')[1];
      const foundPricebook = priceBookList.find(p => p.crmid === indexId);
      setActivePriceBook(foundPricebook);
    }
  }, [meiliIndex]);

  const onSelect = async (selected) => {
    const { entity } = selected;
    if (entity && entity?.crmid !== selectedCustomer?.crmid) {
      if (orderSelected && Object.keys(orderSelected).length > 0 && !enableEditOrder) {
        await dispatch(handleEditOrder());
      }
      await setNewSelectedCustomer(entity);
      // await dispatchSelectCustomer(entity);
      // No hay listas de precios
      const isDefaultPriceBook = (!entity.pricebookid || entity.pricebookid === '0') && (!selectedCustomer.pricebookid || selectedCustomer.pricebookid === '0');
      if (isDefaultPriceBook) {
        await dispatchSelectCustomer(entity);
        return;
      }
      // Ambos tienen la misma lista de precios
      if (entity.pricebookid === selectedCustomer.pricebookid) {
        await dispatchSelectCustomer(entity);
        return;
      }
      // Price books distintos de defaults y diferentes
      const existPriceBook = Boolean(entity.pricebookid
        && entity.pricebookid !== '0'
        && selectedCustomer.pricebookid
        && selectedCustomer.pricebookid !== '0'
        && entity.pricebookid !== selectedCustomer.pricebookid);

      if (existPriceBook) {
        if (pos_can_change_price === '1' && entity.pricebookid && entity.pricebookid !== activePriceBook?.crmid) { // Solo pregunta cuando el user puede o no cambiar la lista (admins casi siempre)
          setOpen(true);
          return;
        }
        // Usuario normal (pos_can_change_price === '0') siempre cambia lista precio
        await dispatchSelectCustomer(entity);
        await dispatch(updateProductsInCartWhenChangePriceBook(entity.pricebookid));
        toast.success('Actualizando lista de precios');
        return;
      }

      // Existe uno de los dos y el otro es el default
      const existOne = (entity.pricebookid
          && entity.pricebookid !== '0'
          && (!selectedCustomer.pricebookid
            || selectedCustomer.pricebookid === '0'))
        || (selectedCustomer.pricebookid
          && selectedCustomer.pricebookid !== '0'
          && (!entity.pricebookid || entity.pricebookid === '0'));

      if (existOne) {
        if (pos_can_change_price === '1' && entity.pricebookid && entity.pricebookid !== activePriceBook?.crmid) { // Solo pregunta cuando el user puede o no cambiar la lista (admins casi siempre)
          setOpen(true);
          return;
        }
        // Usuario normal (pos_can_change_price === '0') siempre cambia lista precio
        await dispatchSelectCustomer(entity);
        await dispatch(updateProductsInCartWhenChangePriceBook(entity.pricebookid));
        toast.success('Revisando lista de precios');
      }
    }
  };

  const onFormattData = (data) => {
    const dataFormatted = data.map(x => ({
      value: x.crmid,
      label: x.accountname,
      entity: x,
    }));
    return dataFormatted;
  };

  const onSearch = (value, callback) => new Promise(() => {
    const customerApi = new CustomerAPI();
    customerApi.getAccountByName(value).then((response) => {
      if (response.success) {
        callback(onFormattData(response.result));
      }
    });
  });

  const handleClose = () => {
    setOpen(false);
  };

  const changePriceBook = async () => {
    await dispatchSelectCustomer(newSelectedCustomer);
    await dispatch(updateProductsInCartWhenChangePriceBook(newSelectedCustomer.pricebookid));

    setTimeout(() => {
      dispatch(updatePromotions());
    }, 1500);
    setOpen(false);
  };

  const formattedCustomer = customers.map(x => ({
    value: x.crmid,
    label: x.accountname,
    entity: x,
  }));
  return (
    <>
      <AsyncSelect
        isDisabled={(!enableEditOrder && activeTab === 'Historico') || (!enableEditOrder && activeTab === 'Cotizaciones')}
        tabIndex="0"
        maxMenuHeight={200}
        onChange={onSelect}
        value={customerSelection}
        defaultOptions={formattedCustomer}
        placeholder="Cambia de cliente"
        inputId="select_customer_searchbar"
        styles={{
          singleValue: base => ({
            ...base,
            ...single,
          }),
          control: base => ({
            ...base,
            ...control,
          }),
        }}
        loadOptions={(value, callback) => {
          clearTimeout(timeout);
          timeout = setTimeout(() => onSearch(value, callback), 500);
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cambio de lista de precios</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            El cliente seleccionado tiene una lista de precios diferente. Desea cambiar los precios?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={changePriceBook} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

SearchCustomer.propTypes = {
  customer: PropTypes.object.isRequired,
  single: PropTypes.object,
  control: PropTypes.object,
  orderSelected: PropTypes.object,
  dispatchSelectCustomer: PropTypes.func.isRequired,
  dispatchEditOrder: PropTypes.func.isRequired,
};
SearchCustomer.defaultProps = {
  single: {},
  control: {},
  orderSelected: {},
};

const mapStateToProps = state => ({
  customer: state.customer,
});

const mapDispatchToProps = dispatch => ({
  dispatchSelectCustomer: customer => dispatch(selectCustomer(customer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchCustomer);
